.contenedor-header {
    position: fixed;
    width: 100%;
    font-family: "Work Sans", sans-serif;
}

.cabecera {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 100px;
}

.logo,
.nombre {
    font-family: "Inter", sans-serif;
    font-size: 36px;
    color: #1CB652;
    text-decoration: none;
}

.navbar {
    display: flex;
}

.lista-ul {
    display: flex;
    gap: 15px;
    list-style: none;
}

.opcion {
    text-align: none;
    color: #fff;
    transition: 0.5s;
    text-decoration: none;
    font-size: 15px;
}

.opcion:hover {
    color: #1CB652;
}

.nav-responsive {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #1CB652;
    color: #fff;
    padding: 10px 10px;
    border-radius: 5px;
    cursor: pointer;
    display: none;
}

@media screen and (max-width: 980px) {
    .navbar.mobile .lista-ul {
        display: none;
        flex-direction: column;
        align-items: flex-end;
        padding: 15px;
        padding-right: 100px;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        transition: opacity 0.3s ease;
    }

    .navbar.mobile .lista-ul.open {
        display: flex;
        opacity: 1;
        
    }

    .navbar.mobile .lista-ul.open li{
        transition: 0.3s;
        padding-bottom: 10px;
        border-bottom: 2px solid #1CB652;
    }

    .navbar.mobile .opcion {
        color: #fff;
        font-size: 18px;
        margin-bottom: 10px;
    }

    .nav-responsive {
        display: flex;
    }
}

@media screen and (max-width: 450px) {

    .navbar.mobile .lista-ul {
        padding-right: 40px;
        gap: 5px;
    }

    .navbar.mobile .lista-ul a{
        font-size: 13px;
    }

    .cabecera{
        padding: 15px 40px;
    }
}