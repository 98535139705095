.portafolio{
    display: flex;
    flex-direction: column;
    background-color: #252b2e;
    color: #fff;
    padding-bottom: 30px;
}

.portafolio h2{
    font-size: 48px;
    font-family: 'Work Sans';
    text-align: center;
    padding-top: 40px;
    padding-bottom: 20px;
}

.contenedores-portafolio{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.card-portafolio{
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 4px;
    box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.3);
}

.card-portafolio:hover{
    padding: 10px;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    box-shadow: 0px 0px 15px 5px rgba(55, 62, 68, 0.3);
    transition: 0.3s;
}

.card-portafolio:hover .img-portafolio{
    width: 380px;
    transition: 0.5s;
}

.img-portafolio{
    width: 370px;
    height: 100%;
    padding-bottom: 10px;
}

.card-portafolio a{
    text-decoration: none;
    color: white;
}

@media screen and (max-width: 400px) {
    .img-portafolio{
        width: 240px
    }
}