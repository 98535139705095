.curriculum{
    display: flex;
    flex-direction: column;
    background: #1e2326;
    color: #fff;
    padding-bottom: 30px;
}

.curriculum h2{
    font-size: 48px;
    font-family: 'Work Sans';
    text-align: center;
    padding-top: 40px;
    padding-bottom: 20px;
}

.contenido-seccion-curriculum{
    display: flex;
    justify-content: space-between;
}

.contenido-seccion-curriculum h3{
    font-size: 30px;
    font-family: 'Work Sans';
    text-align: center;
}

.contenedor-educacion{
    display: flex;
    flex-direction: column;
    width: 42%;
    border-right: 1px solid rgb(68, 67, 67);
    padding-right: 30px;
    margin-left: 100px;
    gap: 30px;
}

.contenedor-experiencia{
    display: flex;
    flex-direction: column;
    width: 42%;
    border-left: 1px solid rgb(68, 67, 67);
    padding-left: 30px;
    margin-right: 100px;
    gap: 30px;
}

.card-educacion{
    padding: 20px 30px;
    display: flex;
    flex-direction: column;
    background-color: #252b2e;
    border-right: 2px solid #1CB652;
}

.contenido-seccion-curriculum h5{
    padding-top: 10px;
    font-size: 20px;
    font-weight: bold;
    color: #1CB652;
}

.fecha{
    color: #1CB652;
    padding-bottom: 5px;
}

.contenido-seccion-curriculum h4 h5{
    font-family: 'Work Sans';
}

.card-experiencia{
    padding: 20px 30px;
    display: flex;
    flex-direction: column;
    background-color: #252b2e;
    border-left: 2px solid #1CB652;
}

p{
    line-height: 24px;
}

@media screen and (max-width: 504px) {
    .contenido-seccion-curriculum.mobile h3{
        font-size: 24px;
    }

    .contenido-seccion-curriculum.mobile{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .contenedor-educacion{
        border: none;
        padding: 0 0 30px 0;
        margin: 0;
        width: 85%;
    }

    .contenedor-experiencia{
        border: none;
        padding: 0 0 30px 0;
        margin: 0;
        width: 85%;
    }

    .card-educacion{
        border-right: 2px solid #1CB652;
        border-left: 2px solid #1CB652;
    }

    .card-experiencia{
        border-right: 2px solid #1CB652;
        border-left: 2px solid #1CB652;
    }
}