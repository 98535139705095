@import url('https://fonts.googleapis.com/css2?family=Tilt+Prism&family=Work+Sans:wght@800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@700&family=Tilt+Prism&family=Work+Sans:wght@800&display=swap');

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body{
  scroll-behavior: smooth;
  scrollbar-width: thin;
  scrollbar-color: #1CB652 transparent;
}

::-webkit-scrollbar {
  width: 8px; /* Ajusta el ancho de la barra de desplazamiento según tus preferencias */
}

::-webkit-scrollbar-track {
  background-color: #181a1b;
}

::-webkit-scrollbar-thumb {
  background-color: #1CB652; /* Color personalizado para la barra de desplazamiento */
  border-radius: 4px; /* Ajusta el radio del borde según tus preferencias */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #1CB652; /* Color personalizado para la barra de desplazamiento al pasar el cursor sobre ella */
}