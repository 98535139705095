.contacto{
    background: linear-gradient(to top, #1e2326cc, rgba(30, 35, 38, 1)), url("../images/contacto-fondo.jpg");
    display: flex;
    flex-direction: column;
    background-color: #1e2326;
    color: #fff;
    padding-top: 30px;
    padding-bottom: 60px;
    align-items: center;
}

.contacto h2{
    font-size: 48px;
    font-family: 'Work Sans';
    text-align: center;
    padding-top: 40px;
    padding-bottom: 20px;
}

.contenido-seccion-contacto{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
    width: 550px;
    padding-top: 30px;
    padding-bottom: 30px;
}

.datos{
    height: 30px;
    width: 250px;
}
.mensaje{
    height: 200px;
    width: 530px;
    resize: none;
}

.enviar{
    cursor: pointer;
    text-decoration: none;
    width: fit-content;
    background-color: transparent;
    border: none;
    display: block;
    margin: 20px auto;
    padding: 10px 45px;
    font-size: 16px;
    color: #fff;
    position: relative;
    z-index: 10;
    background-color: #1CB652;
    border-radius: 50px;
}

.enviar .enviar-overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-color: #1CB652;
    z-index: .1;
    transition: 1.6s;
    border-radius: 50px;
}

.enviar:hover .enviar-overlay{
    width: 100%;
    border-radius: 50px;
}

.datos, .mensaje{
    color: #fff;
    padding: 18px;
    font-family: 'Work Sans';
    display: block;
    border: none;
    background-color: #252A2E;
    outline: none;
    font-size: 18px;
}

.datos:focus, .mensaje:focus {
  outline-color: #147460;
  outline-style: solid;
  outline-width: 2px;
  transition: 0.15s;
}

.datos:hover, .mensaje:hover{
    background-color: #2c3135;
}

@media screen and (max-width: 550px) {

    .contenido-seccion-contacto{
        max-width: 330px;
    }
    
    .contenido-seccion-contacto.mobile .datos{
        height: 30px;
        width: 150px;
        font-size: 10px;
    }
    .contenido-seccion-contacto.mobile .mensaje{
        height: 200px;
        width: 330px;
        font-size: 10px;
        resize: none;
    }
}

@media screen and (max-width: 280px) {
    .contacto{
        display: n;
    }
    .contenido-seccion-contacto{
        max-width: 280px;
    }
    
    .contenido-seccion-contacto.mobile .datos{
        height: 30px;
        width: 110px;
        font-size: 10px;
    }
    .contenido-seccion-contacto.mobile .mensaje{
        height: 200px;
        width: 250px;
        font-size: 10px;
        resize: none;
    }
}