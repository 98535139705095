.inicio{
    background: linear-gradient(to top, rgba(56, 59, 61, 0.8), rgba(30, 35, 38, 1)), url("../images/fondo.jpg");
    background-size: cover;
    height: 100vh;
    color: #fff;
    display: flex;
    align-items: center;
}

.contenido-banner{
    height: 65vh;
    padding: 20px;
    max-width: 350px;
    margin: auto;
    text-align: center;
    border-radius: 40px;
}

.img-cv{
    width: 70%;
    border-radius: 100%;
    display: block;
    margin: auto;
}

.contenido-banner h1{
    margin-top: 10px;
    font-size: 42px;
    font-family: 'Righteous';
}

.contenido-banner h2{
    font-size: 15px;
    font-weight: normal;
}

.redes a{
    color: #fff;
    text-decoration: none;
    display: inline-block;
    border: 1px solid #fff;
    border-radius: 100%;
    width: 42px;
    height: 42px;
    line-height: 42px;
    margin: 10px 2px;
    font-size: 20px;
    transition: .3s;
}

.redes a:hover{
    background-color: #1CB652;
}

.redes{
    display: flex;
    justify-content: center;
    gap: 5px;
}

@media screen and ( max-width: 768px ){
    .contenido-banner.mobile{
        height: 80vh;
    }
}